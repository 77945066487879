import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { SpeedTestService } from "ng-speed-test";
import { Subject, Subscription, takeUntil } from "rxjs";
import { AppModesEnum } from "src/app/models/WebServer/appModesEnum";
import { PartecipantStatus } from "src/app/models/WebServer/partecipantStatus";
import { Session } from "src/app/models/WebServer/session";
import { AppConfigService } from "src/app/services/app.config.service";
import { FELoggerService } from "src/app/services/feLogger.service";
import { SessionService } from "src/app/services/session.service";
import { RootState } from "src/app/store/root.state";
import { AppState } from "src/app/store/states/app.state";
import * as selectorSessionStatus from "src/app/store/selectors/sessionStatus.selectors";
import * as appActions from "src/app//store/actions/app.actions";
import { NotificationService } from "src/app/services/notification.service";
import { CheckTypeEvent, CheckerService, MAX_NOTIFY_EXPIRATION, TickEvent } from "src/app/services/checker.service";
import { DisclaimerHelper } from "src/app/helpers/disclaimer.helper";
import { I18Service } from "src/app/services/i18.service";


@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() AS!: AppState;
  @Input() MYSTATUS!: PartecipantStatus;

  // @Input() session!: Session;

  @ViewChild('callerinfo',{static: false})
  callerinfo!: ElementRef;
  @ViewChild('sessioninfo',{static: false})
  sessioninfo!: ElementRef;
  
  private destroy$: Subject<boolean> = new Subject<boolean>();
  ticksubscription: Subscription;

  expirationMillisec: number = MAX_NOTIFY_EXPIRATION * 1000;
  // expiresIn = (new Date()).getTime();

  PRIMARYSTATUS?: PartecipantStatus;
  SECONDARYSTATUS?: PartecipantStatus;

  showUserInfo = true;
  constructor(
    public config: AppConfigService,
    private store: Store<RootState>,
    private checkerService: CheckerService,
  ) {

    this.ticksubscription =this.checkerService.tick$.subscribe({
      next: (x: TickEvent) => {
        if (x.type === CheckTypeEvent.sessionExpiration) {
          var z =  Math.ceil((x.val / 1000) / 5) * 5;
          this.expirationMillisec = z * 1000; // arrotondare...
          // this.expiresIn = this.expirationMillisec;
        }
      }
    })

    this.store.select(selectorSessionStatus.primaryPartecipantStatus).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => this.PRIMARYSTATUS = data);
    
    this.store.select(selectorSessionStatus.secondaryPartecipantStatus).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => this.SECONDARYSTATUS = data);

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    this.ticksubscription.unsubscribe();
  }

  async ngOnInit() {
    DisclaimerHelper.showDisclaimer()
      .then(result => {
        const afterInit = result || !this.config.agencySettings.blockingDisclaimer;
        console.log(`***** DisclaimerAccepted ${afterInit}`)
        if (!afterInit) {
          this.store.dispatch(appActions.showErrorPage({ message: I18Service.get('ERRORPAGE.DISCLAIMER'), imagePath: 'assets/images/alert-error.svg' }));
        }
      });
  }

  async ngAfterViewInit() {
  }


  changeMode(mode: AppModesEnum) {
    this.store.dispatch(appActions.appModeChange({ mode }));
  }


  endSession() {
    // this.sipService.unregister();
    // if (this.position) {
    //   this.position.stopSendingPosition();
    //   this.eventEmitterService.riseError(new ErrorInfo('assets/images/thankyou.jpg', this.selectorPlaceHolder.get('LABELS.SESSIONEND')));
    // }
    // this.AS.sessionExpired = true;
    // this.config.setExpired(true);
  }
  showGuestSessionInfo(show: boolean) {
    show && NotificationService.showElement(this.sessioninfo.nativeElement, '');
    !show && NotificationService.popupClose();

  }
  showGuestCitizenInfo(show: boolean) {
    show && NotificationService.showElement(this.callerinfo.nativeElement, '');
    !show && NotificationService.popupClose();
  }
}
