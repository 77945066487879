import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { InlineWorker } from "./inline-worker";
import { Store } from "@ngrx/store";
import { RootState } from "../store/root.state";
import { AppConfigService } from "./app.config.service";
import { AppHelper, PageStatusEnum } from "../helpers/app.helper";
import { PhoneStatusEnum } from "../models/phoneStatus";
import * as appActions from "../store/actions/app.actions";
import * as sessionActions from "../store/actions/session.actions";
import { Observable, Subscription } from "rxjs";
import { I18Service } from "./i18.service";
import { SpeedTestService } from "ng-speed-test";
import { FELoggerService, LogSource } from "./feLogger.service";
import { SipService } from "./sip.service";

export enum CheckTypeEvent {
  // tickEvent = 'tickEvent',
  sendLocationInfo = 'sendLocationInfo',
  sessionExpiration = 'sessionExpiration',
  batteryLevel = 'batteryLevel',
  connectionSpeed = 'connectionSpeed',
  versionChange = 'versionChange',
  tryNewGeoLocAttempt = 'tryNewGeoLocAttempt'
}

const TICK_INTERVAL = 5 * 1000;
const NOTIFY_EXIRATION = [30, 60, 120];
export const MAX_NOTIFY_EXPIRATION = Math.max(...NOTIFY_EXIRATION);
const RESET_NOTIFY_EXPIRAION = (MAX_NOTIFY_EXPIRATION + 30) * 1000;

export type TickEvent = {
  type: CheckTypeEvent,
  val: any
}

/* tramite tick webworker esegue una serie di controlli 
 - // versionChange 30s
 - basicCredentialsClean??? 20s
 - // notifyPresence 5s [ora spostata su sigr]
 - updateStatus (isCaller) 30s

 - connectionSpeed (caller) 300s

 - newInviteInterval (receiver) 1s if AskForNewInvite []

 - batteryInterval (isCaller) 10s
 - sessionExpiration 2s
 - sendLocationInfo (isCaller) tramite worker (sendPositionInterval 30s fino ad un sendPositionTimeout?? 15m)

*/

@Injectable({
  providedIn: 'root'
})
export class CheckerService implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private tickWorker: InlineWorker;
  private prevTick = {
    sendLocationInfo: 0,
    sessionExpiration: {}, //usato per l'invio di una sola notifica
    batteryLevel: 0,
    connectionSpeed: 0,
    versionChange: 0,
    anyoneToCall: 0
  };
  public tick$: EventEmitter<TickEvent> = new EventEmitter<TickEvent>();

  private checkConnectionSpeed=false;

  constructor(private store: Store<RootState>,
    private sipService: SipService,
    private speedTestService: SpeedTestService,
    private config: AppConfigService) {

    // this.prevTick = new Date().getTime();

    
    this.tickWorker = new InlineWorker(() => {
      console.log('CheckerService Start tickWorker thread, wait for postMessage');
      const selfThis = self;
      // const _window = window;
      let intervalTimer: any = 0;
      const tick = () => { selfThis.postMessage({ message: 'tick', postTime: new Date().getTime() }); };

      const startTickInterval = (tickInterval: number) => {

        tick();
        intervalTimer = setInterval(tick, tickInterval);
      };

      const stopTickInterval = () => {
        if (intervalTimer) {
          clearInterval(intervalTimer);
          intervalTimer = 0;
          console.log('CheckerService stopTickInterval: stopped!');
          selfThis.postMessage({ message: 'stopped' });
        }
      };

      selfThis.onmessage = (evt) => {

        switch (evt.data.action) {
          case 'start':
            if (!intervalTimer) {
              console.log(`CheckerService Starting tickInterval thred every: ${JSON.stringify(evt.data.value)}`);
              startTickInterval(evt.data.value.tickInterval);
            }
            break;
          case 'stop':
            stopTickInterval();
            break;
        }
      };
      // END OF WORKER THREAD CODE
    });

    const selfThis = this;
    this.tickWorker.onerror().subscribe((err) => { FELoggerService.error(`tickWorker error:`, LogSource.Default, err); });
    this.tickWorker.onmessage().subscribe((msg) => {

      switch (msg.data.message) {
        case 'stopped':
          break;
        case 'tick':
          selfThis.handleTick();
          // selfThis.prevTick = msg.data.postTime;
          //this.store.dispatch(new StartKeepAliveAction());
          break;
      }
    });
  }

  init() {
    console.log(`CheckerService tick init start`);
    this.tickWorker.postMessage({ action: 'start', value: { tickInterval: TICK_INTERVAL } });
    this.windowPageListners();
  }
  stop() {
    this.ngOnDestroy();
  }

  private handleTick() {
    //console.log(`CheckerService handleTick`);
    const now = new Date().getTime();
    // console.log(`evt_change tick`);
    if (this.config.AS?.isCaller) {
      this.sendLocationInfo(now);
      this.batteryLevel(now);
      this.connectionSpeed(now);
    }
    // if (!this.config.AS?.isReceiver) {
    this.sessionExpiration(now);
    // }
    // this.prevTick = new Date().getTime();

    this.anyoneToCall(now)
  }

  private versionChange(now: number) { }
  private connectionSpeed(now: number) {
    if ((now - this.prevTick.connectionSpeed) < (this.config.agencySettings?.checkInternetSpeed || 300000) || !this.checkConnectionSpeed) {
      this.checkConnectionSpeed = true;
      return;
    }
    
    if (this.config.iamOnCall) return;
    
    console.log(`connectionSpeed start`)
    this.prevTick.connectionSpeed = now;

    this.speedTestService.getMbps().subscribe(
      (speed) => {
        console.log(`connectionSpeed dispatch result ${speed}`)
        this.store.dispatch(appActions.connectionSpeed({ speed: speed + '' }));
      }
    );

  }
  private batteryLevel(now: number) {
    if ((now - this.prevTick.batteryLevel) < (this.config.agencySettings?.checkBatteryInterval || 60000)) {
      return;
    }

    this.prevTick.batteryLevel = now;

    const _navigator: any = window.navigator;
    const batteryChk = _navigator['battery'] || _navigator['getBattery'] ||
      _navigator['webkitBattery'] || _navigator['mozBattery'] ||
      _navigator['msBattery'];

    var newVal = I18Service.get('LABELS.MISSINGINFO');

    const selfThis = this;
    const dispatch = function () {
      if (selfThis.config?.AS?.batteryLevel != newVal) {
        selfThis.store.dispatch(appActions.batteryLevel({ level: newVal }));
      }
    }

    if (batteryChk && _navigator.getBattery) {
      _navigator.getBattery().then((battery: any) => {
        if (!battery) { dispatch(); return; }
        newVal = `${Math.floor(battery.level * 100)}%`;
        dispatch();
      });
    } else {
      dispatch();
    }



  }

  // verifica scadenza sessione, ma avvisa a 2m 1m e 30s
  private sessionExpiration(now: number) {
    const expirationTime = (this.config.AS?.session.expiration) ? (new Date(this.config.AS?.session.expiration)).getTime() : Number.MAX_SAFE_INTEGER;

    // qualora ci sia un extend session: reset degli "avvisi"
    if (expirationTime - RESET_NOTIFY_EXPIRAION > now) {
      this.prevTick.sessionExpiration = {};
    } 
    else if (expirationTime < now) {
      // adesso tutti ricevono la scadenza aggiornata, ma non quando scade naturalmente
      console.log(`checkerService terminateSession`)
      this.store.dispatch(sessionActions.terminateSession());
    } 
    else if (!this.config.isCaller && expirationTime > now) {
        // verifica se siamo in uno dei range di avviso "expiration"
      for (var t of NOTIFY_EXIRATION) {
        const remaining = expirationTime - t * 1000;
        if (remaining < now) {

          const tt = t * 1000; //expirationTime - now; 
          const sessionExpirationNotified = (this.prevTick.sessionExpiration as any)

          if (!sessionExpirationNotified[t] && !this.config.AS?.sessionExtending) {
            this.store.dispatch(sessionActions.notifyExpiration({ in_msec: tt }));
          }
          // console.log(`sessionExpiration ${t}: ${new Date(this.config.AS?.session.expiration+'')} ${new Date(now)}`, this.prevTick.sessionExpiration)
          sessionExpirationNotified[t] = true
          break;
        }
      } 
    } else {this.prevTick.sessionExpiration = {};}

    const remaining = (expirationTime - now) >0 ? expirationTime - now : 0;
    this.tick$.next({ type: CheckTypeEvent.sessionExpiration, val: remaining })
  }
  private sendLocationInfo(now: number) {

    if (now - this.prevTick.sendLocationInfo < (this.config.agencySettings?.sendPositionInterval || 30000)) return;
    // console.log(`tick sendLocationInfo ${now - this.prevTick.sendLocationInfo}`)
    this.prevTick.sendLocationInfo = now;
    this.tick$.next({ type: CheckTypeEvent.sendLocationInfo, val: '' });

  }

  private anyoneToCall(now: number) {
    this.prevTick.anyoneToCall = now;
    this.sipService.checkPartecipantToCall();
  }


  public static oldPageEvent='';
  public static oldPageState='';
  private windowPageListners() {


    const onPageState = (type: string, $e: any) => {

      if (CheckerService.oldPageEvent == type) return;
      // selfThis.logStateChange(AppHelper.getPageState());//
      let nextState = AppHelper.getPageState();
      if (type == 'freeze') { nextState = PageStatusEnum.frozen;}
      if (type == 'pagehide') { nextState = ($e.persisted) ? PageStatusEnum.frozen : PageStatusEnum.terminated; }
      if (type == 'visibilitychange') { nextState = PageStatusEnum.passive; }

      if (CheckerService.oldPageState != nextState) {
        console.log(`checkerService pageState: ${CheckerService.oldPageEvent} => ${type} [${nextState}]`);
        this.store.dispatch(appActions.pageStateChange({ nextState }))
      }
      CheckerService.oldPageState = nextState;
      CheckerService.oldPageEvent = type;
    }

    onPageState('init', null);

    ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume', 'offline', 'online', 'freeze', 'pagehide']
    .forEach((type) => {
      window.addEventListener(type, ($e: any) => {

        onPageState(type, $e);

      }, { capture: true });
    });

  }

  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
    this.tickWorker.postMessage({ action: 'stop' });
    ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume', 'offline', 'online', 'freeze', 'pagehide'].forEach(type => {
      window.removeEventListener(type, () => { });
    });
  }

  newGeoLocAttempt(){
    this.tick$.next({ type: CheckTypeEvent.tryNewGeoLocAttempt, val: '' })
  }

}
