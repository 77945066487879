import {
  Component,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  Input
} from '@angular/core';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { GeoCastingResult } from 'src/app/models/WebServer/geoCastingResult';
// import { AgmMap } from '@agm/core';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';
import { Constants } from 'src/app/constants';
import { GeoLocCoords, CoordsClone, CoordsToGMaps } from 'src/app/models/coordinates';
import { AppConfigService } from 'src/app/services/app.config.service';
import { GeoCastingService } from 'src/app/services/geocasting.service';
import { FELoggerService, LogSource } from 'src/app/services/feLogger.service';
import { AppState } from 'src/app/store/states/app.state';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/root.state';
import { I18Service } from 'src/app/services/i18.service';
import * as sessionStatusActions from "src/app/store/actions/sessionStatus.actions";

import { NotificationService } from 'src/app/services/notification.service';
import { PartecipantStatus } from 'src/app/models/WebServer/partecipantStatus';
import { AddressInfo } from 'src/app/models/WebServer/addressInfo';
import { AppHelper } from 'src/app/helpers/app.helper';
import { BrowserHelper } from 'src/app/helpers/browser.helper';
import { StatusChangeEvent } from 'src/app/models/statusChangeEvent';
import {GoogleMap, MapMarker} from '@angular/google-maps';

const MIN_POSITION_DISPATCH_TIME_SECS = 5;
const INITIAL_ZOOM_LEVEL = 6.5;
const DISTANCE_THRESHOLD = 10;
@Component({
  selector: 'app-position-manager',
  templateUrl: './position-manager.component.html',
  styleUrls: ['./position-manager.component.scss'],
})
export class PositionManagerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('mapContainer') mapContainer!: GoogleMap;

  @Input() AS!: AppState;
  @Input() MYSTATUS?: PartecipantStatus;

  watchId?: number;

  // variabili di stato
  appModes = AppModesEnum;
  sendPosition = true;
  subscribedToGoolge = false;
  firstRegistration = true;
  sendingEvent = false;
  firstSend = false;
  connectionStatus = 'redStatus';
  expirationWarning = 0;
  zoomLevel = INITIAL_ZOOM_LEVEL;
  constants = Constants;

  // variabili sensori e servizi
  geoCastingResult: GeoCastingResult | undefined;
  resizedSize: number | undefined;
  lastGeoCastLat: number | undefined;
  lastGeoCastLog: number | undefined;
  fileReader: FileReader | undefined;
  mapInfo: string = '';
  
  currentLocation: GeoLocCoords = { latitude: Constants.DEFAULT_LATITUDE, longitude: Constants.DEFAULT_LONGITUDE };
  mapCenter: google.maps.LatLngLiteral = {lat: Constants.DEFAULT_LATITUDE, lng: Constants.DEFAULT_LONGITUDE};


  currentAddress?: AddressInfo;
  message!: string;
  status!: string;

  // performance variables
  elapsedSendEvent!: number;
  elapsedSendEventMedia!: number;
  elapsedKamailioConnection!: number;

  permissionValue: number = -1;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  title = AppHelper.getTitleName();
  constructor(
    private store: Store<RootState>,
    public config: AppConfigService,
    private geoCastingService: GeoCastingService) { }

  ngOnDestroy(): void {
    console.log(`position-manager: ngOnDestroy`)
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();

    if (window.navigator && this.watchId) {
      window.navigator.geolocation.clearWatch(this.watchId);
    }
  }

  ngAfterViewInit() {
    this.setEventSubscription();
  }

  setEventSubscription() {

    if (this.config.isCaller && this.config.sessionConfig?.localization) {
      this.informAboutOwnPermission('geolocation');
      this.getPosition();
    }
  }


  onMapReady(map?: google.maps.Map) {
    console.log(`position: map ready`, map)
    if (!map) return;
    map.setOptions({
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      zoomControl: true
    }); 

  }


  private geoCasting(latitude: string, longitude: string): Promise<AddressInfo | null> {
    return new Promise<AddressInfo | null>(resolve => {
      // if (this.lastGeoCastLog != +longitude && this.lastGeoCastLat != +latitude) {
      //   this.lastGeoCastLog = +longitude;
      //   this.lastGeoCastLat = +latitude;
      const self = this;
      self.geoCastingService.geoCastingBackEnd(latitude, longitude).subscribe({
        next: (ai) => {

          resolve(ai);

        },
        error: (err) => {
          FELoggerService.error(`GeoCasting Fallito`, LogSource.StartGeoLocationTracking, err);
          resolve(null)
        }
      });
    })
  }

  private async getPosition() {
    console.log(`startGeoLocationTracking getPosition`)
    const self = this;
    let geoTimeOut = this.config.geoPermissionTimeout || 60000;

    if (!this.watchId) {
      var location_timeout = setTimeout(() => {
        this.handleGeoError({ code: GeolocationPositionError.TIMEOUT, message: 'Position acquisition timed out' })
      }, (geoTimeOut - 1));
    }

    navigator.geolocation.getCurrentPosition((position) => {
      Swal.close();
      clearTimeout(location_timeout);
      const actual: GeoLocCoords = CoordsClone(position.coords);
      self.currentLocation = actual;
      self.mapCenter = CoordsToGMaps(actual);
      console.log(`startGeoLocationTracking getPosition found:`, actual)
      
      this.setPermission('geolocation', 1);
      
      this.startGeoLocationTracking();
    }, (err: GeolocationPositionError) => {
      clearTimeout(location_timeout);
      this.handleGeoError(err);
    });
  }

  private async startGeoLocationTracking() {
    console.log(`startGeoLocationTracking`)
    if (this.watchId) {
      console.log(`startGeoLocationTracking - already watching????!!!`)
      return;
    }

    const self = this;
    const options = {
      enableHighAccuracy: true,
      maximumAge: Infinity
    };

    var firstSend = true;
    let timePassedAfterAcceptance = new Date().getTime();

    self.watchId = navigator.geolocation.watchPosition(
      (position: GeolocationPosition) => {
        var elapsed = new Date().getTime() - timePassedAfterAcceptance;
        const minAccuracy = this.config?.agencySettings?.minAccuracy || 0;
        const sendPositionThreshold = this.config?.agencySettings?.sendPositionThreshold || 20000;

        const actual: GeoLocCoords = CoordsClone(position.coords);
        const distance = this.getDistanceFromLatLon(actual, this.currentLocation);
        if (firstSend && (position.coords.accuracy <= minAccuracy || elapsed >= sendPositionThreshold) || distance > DISTANCE_THRESHOLD) {

          self.sendUpdateLocation(actual);
          firstSend = false;
          console.log(`GeoLocationTracking watching`, actual);
        }
      },
      (err) => {
        this.handleGeoError(err);
      },
      options);

  }

  handleGeoError(error: GeolocationPositionError | { code: number, message: string }, popup: boolean = true) {
    //Display error based on the error code.
    console.error(error);
    FELoggerService.error(`ERROR WITH GEOLOCATION --> CODE:`, LogSource.StartGeoLocationTracking, error);//, Framework.Localization);
    const { code } = error;
    switch (code) {
      //TIMEOUT
      case 3:
        if (popup) {
          NotificationService.popupInfo({msg:I18Service.get('SA_TIMEOUTGLBODY'), confirmButton:I18Service.get('SA_TIMEOUTGLBTN')})
          .then(() => {
            this.newGeoAttempt();
          });
        }
        break;
      //DENIED
      case 1:
        this.setPermission('geolocation', 2);
        if (popup) {
          NotificationService.popupWarn({msg:I18Service.get('SA_DENIEDGLBODY'), confirmButton:I18Service.get('SA_TIMEOUTGLBTN')})
          .then(() => {
            this.newGeoAttempt();
          });
        }
        break;
      //POSITION_UNAVAILABLE
      case 2:
      default:
        if (popup) {
          NotificationService.popupWarn({msg:I18Service.get('SA_UNAVAILABLEGLBODY'),confirmButton:I18Service.get('SA_TIMEOUTGLBTN')})
            .then(() => {
              this.geoAttemptByReloading();
          });
        }
        break;
    }
  }

  async newGeoAttempt() {
    // await this.informAboutOwnPermission('geolocation');

    if (window.navigator && this.watchId) {
      window.navigator.geolocation.clearWatch(this.watchId);
      this.watchId = undefined;
    }

    NotificationService.timedToastWarn(I18Service.get('SA_GLNEWATTEMPT'), undefined, 2000, 'top', '80vw')
    .then(() => {
      this.getPosition();
    });
  }

  private geoAttemptByReloading() {
    // Send here notification to CAD
    NotificationService.timedToastWarn(I18Service.get('SA_GLRELOADATTEMPT'), undefined, 1500, 'top', '80vw')
    .then(() => {
      window.location.reload();
    });
  }

  async geoLocationError(errCode: number = 0, errMessage: string = '') {
    setTimeout(async () => {
      let title = I18Service.get('POPUP.GEOERRORBODY');
      let message = I18Service.get('POPUP.NAVIGATORNOTEXISTS');
      title = title ? title : 'Impossibile inviare la posizione';
      message = message ? message : `Non siamo in grado di leggere la tua posizione. Per favore prova a ricaricare la pagina`;
      FELoggerService.error(`Impossibile leggere la posizione: '${errMessage}'`, LogSource.Localization);
      NotificationService.popupWarn({msg:message, confirmButton:I18Service.get('SA_TIMEOUTGLBTN')});
    }, 5000);
  }

  private alreadySendingLocation = false;
  async sendLocationImmediately(snackbar: boolean = true) {
    if (this.alreadySendingLocation) return;
    this.alreadySendingLocation = true;
    // console.log(`sendLocationImmediately ***************************`)
    await this.informAboutOwnPermission('geolocation');
    const options = {
      enableHighAccuracy: true,
      maximumAge: Infinity
    };

    navigator.geolocation.getCurrentPosition((pos: GeolocationPosition) => {
      const actual: GeoLocCoords = CoordsClone(pos.coords);
      this.sendUpdateLocation(actual);
      this.alreadySendingLocation = false;
      if(snackbar && this.permissionValue == 1) {
        NotificationService.showSuccess(I18Service.get(`SNACKBAR.POSITIONSENT`));
      }
      if (snackbar && this.permissionValue != 1) {
        this.handleGeoError({code: 1, message:'Permission removed'});
      }
    }, (err: GeolocationPositionError) => {
      this.alreadySendingLocation = false;
      this.handleGeoError(err);
    }, options);

  }

  async sendCurrentLocation() { 
    await this.informAboutOwnPermission('geolocation');
    this.sendUpdateLocation(this.currentLocation); 
  }

  private sendUpdateLocation(position: GeoLocCoords) {

    if (this.zoomLevel == INITIAL_ZOOM_LEVEL) this.zoomLevel = 13; //al primo send.. zooma 

    if (this.permissionValue != 1) {
      NotificationService.showError(I18Service.get('SA_DENIEDGLTITLE'));
      return;
    }
    //evita richiesta di geoCast se coord non cambiano
    const distance = this.getDistanceFromLatLon(position, this.currentLocation);
    console.log(`GeoLocationTracking - sendUpdateLocation distance: ${distance}`, position);
    
    if (this.currentAddress && distance < DISTANCE_THRESHOLD) { // se distanza inferiore non richiede geocast
      this.dispatchPosition(position, this.currentAddress);
      return
    }
    this.currentLocation = position;
    this.mapCenter = CoordsToGMaps(position);

    this.geoCasting(position.latitude + '', position.longitude + '').then((ai) => {
      console.log(`GeoLocationTracking - geocasting`, ai);
      if (ai) this.currentAddress = ai;
      this.dispatchPosition(position, ai);
    });
  }


  async informAboutOwnPermission(permissionName: PermissionName) {

    if (navigator.permissions ) {
      try {
        var permissionStatus = await navigator.permissions.query({ name: permissionName });

        console.debug(`informAboutOwnPermission ${permissionName} ${permissionStatus.state}`, permissionStatus);//, Framework.Localization);
        var permissionValue = 0; // 'prompt'

        if (permissionStatus.state == 'granted') {
          permissionValue = 1
        } else if (permissionStatus.state == 'denied') {
          permissionValue = 2
        }
        
         if(permissionValue == 0 && (BrowserHelper.isSafari() )) {
          // safari restituisce sempre prompt (a meno di non dare un ok complessivo)
          // filtriamo questi casi in modo da non avere sorprese
        }
        else {
          this.setPermission(permissionName, permissionValue);
        }

      }
      catch (error) {
        FELoggerService.error(`Unable to check '${permissionName}' permission in this browser`, LogSource.StartGeoLocationTracking, error);
        this.setPermission(permissionName, 0);
      }
    }
    // }
  }

  setPermission(permissionName: PermissionName, status: number) {

    if(this.permissionValue === status) return;
    console.log(`setPermission ${permissionName} => ${status}`)
    this.permissionValue = status;

    switch (permissionName) {
      case "geolocation":
        this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.GeoPermissionUpdated, val: status }))
        break;
      default:
        break;
    }
  }

  // manageUnregistration() {
  // }

  
  // checkSessionExpiration(): boolean { // spostato sul checkerService
  //   return false;
  // }

  setMessage(message: string) {
    this.message = message;
  }

  lastDispatch = 0;
  private dispatchPosition(position: GeoLocCoords, ai: AddressInfo | null) {

    const now = new Date().getTime();

    if (now < this.lastDispatch + 1000 * MIN_POSITION_DISPATCH_TIME_SECS) {
      //filtra i dispatch inferiori di 5 secondi
      return;
    }
    this.lastDispatch = now;

    const multiStatus = [
      { evt: StatusChangeEvent.GeoLocCoords, val: position },
      { evt: StatusChangeEvent.AddressInfo, val: ai },
      // { evt: StatusChangeEvent.IsRegistered, val: this.AS.isRegistered}
    ]
    this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MultipleUpdateStatus, val: multiStatus }));
  }


  private getDistanceFromLatLon(pos1: GeoLocCoords, pos2: GeoLocCoords): number {
    const lat1 = pos1.latitude, lat2 = pos2.latitude, lon1 = pos1.longitude, lon2 = pos2.longitude;
    const R = 6371 * 1000; // Radius of the earth in m
    const dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2-lon1); 
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c; // Distance
    return d;
  }
  
  private deg2rad(deg: number): number {
    return deg * (Math.PI/180)
  }


}
