import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogContent, DialogCustomActions } from "../models/dialogContent";
import { AppHelper } from "./app.helper";
import { DialogBodyComponent } from "../components/dialogs/dialog-body.component";
import { Session } from "../models/WebServer/session";
import { TranslateService } from "@ngx-translate/core";
import { I18Service } from "../services/i18.service";
import { FELoggerService, LogSource } from "../services/feLogger.service";
import { PartecipantRoles } from "../models/WebServer/partecipantRoles";
import { AppConfigService } from "../services/app.config.service";
import { AgencySettings } from "../models/WebServer/agencySettings";
import { Injectable } from "@angular/core";


type DisclaimerHelpItem = {
    sessionId: string,
    accepted: boolean,
    timestamp: number
}
@Injectable({
    providedIn: 'root'
  })
export class DisclaimerHelper {

    static readonly DISCLAIMER_PREF = 'DisclaimerPref';
    private static s_matDialog: MatDialog;
    private static s_config: AppConfigService;
    constructor(config: AppConfigService, matDialog: MatDialog) {
        console.debug(`***************** DisclaimerHelper constructor`)
        DisclaimerHelper.s_matDialog = matDialog; 
        DisclaimerHelper.s_config = config;
        
    }

    static showDisclaimer(onOpenCkb: any = undefined): Promise<boolean> {
        const promise: Promise<boolean > = new Promise<boolean >( resolve => {
            if (!DisclaimerHelper.s_config.agencySettings.showDisclaimer) {
                resolve(true);
                return;
            }
            if ( this.checkDisclaimerPref(DisclaimerHelper.s_config.sessionId!, DisclaimerHelper.s_config.agencySettings.disclaimerMinTimeout) ) {
                resolve(true);
                return;
            }
            const role= AppConfigService.role == PartecipantRoles.SecondaryClient ? '' : '_guest';
            var disclaimerUrl = `/assets/disclaimers/${DisclaimerHelper.s_config.agencySettings.agency.toLowerCase()}/disclaimer${role}.html`;
            if (!AppHelper.fileExists(disclaimerUrl)) {
                disclaimerUrl = `/assets/disclaimers/_default/disclaimer${role}.html`;
            }

            console.log(`showDisclaimer`, disclaimerUrl)
            const html = AppHelper.loadTextFile(disclaimerUrl);
            if (!html) {
                FELoggerService.warn(`showDisclaimer ${disclaimerUrl} NOT FOUND! (autoresolve)`)
                resolve(true);
                return;
            }
            const cancelButton = I18Service.get('POPUP.CANCEL')
            const blockingDisclaimer = DisclaimerHelper.s_config.agencySettings.blockingDisclaimer;
            const disclaimerContent = new DialogContent(DisclaimerHelper.s_config.agencySettings.disclaimerHeaderText, html, blockingDisclaimer, false);
            disclaimerContent.actions.push(new DialogCustomActions('OK', false, 1));
            blockingDisclaimer && disclaimerContent.actions.push(new DialogCustomActions(cancelButton, true, 2));
            const disclaimerDialogRef: MatDialogRef<DialogBodyComponent, any> = AppHelper.openDialog(DisclaimerHelper.s_matDialog, disclaimerContent, '80%', '80%');
            onOpenCkb && onOpenCkb(disclaimerDialogRef);
            disclaimerDialogRef.afterClosed().subscribe(ans => {
                console.log(`showDisclaimer ans: ${ans}`);
                const result = (ans==1) ? true : false;
                this.saveDisclaimerPref(DisclaimerHelper.s_config.sessionId!, result);
                resolve(result);
            });
        });
    // })
        return promise
    }




    private static checkDisclaimerPref(sessionId:string, timeoutInMin: number): boolean {
        const key=`${DisclaimerHelper.DISCLAIMER_PREF}.${AppConfigService.role}`
        const disclPref = localStorage.getItem(key);
        if (disclPref) {
            try {
                const saved: DisclaimerHelpItem = JSON.parse(disclPref);
                if (saved.sessionId !== sessionId) return false;
                if (!saved.accepted) return false;
                const diffTimeInMin = Math.floor( ((new Date()).getTime() - saved.timestamp) / (1000*60) );
                if (diffTimeInMin < timeoutInMin) return true;
            } catch(e){ FELoggerService.warn(`checkDisclaimerPref`, LogSource.Default, e)}
        }

        return false;
    }
    private static saveDisclaimerPref(sessionId: string, accepted: boolean) {
        const key=`${DisclaimerHelper.DISCLAIMER_PREF}.${AppConfigService.role}`
        const disclaimer: DisclaimerHelpItem = {
            sessionId: sessionId,
            accepted,
            timestamp: (new Date()).getTime()
        }
        localStorage.setItem(key, JSON.stringify(disclaimer));
    }
}