import { Action, createReducer, on } from "@ngrx/store";
import { AppState, initialAppState } from "../states/app.state";

import * as appActions from "../actions/app.actions";
import * as phoneActions from "../actions/phone.actions";
import * as sessionActions from "../actions/session.actions";
import { ErrorInfo } from "../../models/errorInfo";
import { AppHelper } from "src/app/helpers/app.helper";
import { I18Service } from "src/app/services/i18.service";
import { AppModesEnum } from "src/app/models/WebServer/appModesEnum";
import { MediaTypesEnum } from "src/app/models/WebServer/mediaTypesEnum";
import { Message } from "src/app/models/WebServer/message";
import { initialSessionConfig } from "src/app/models/WebServer/sessionConfig";
import { PartecipantRoles } from "src/app/models/WebServer/partecipantRoles";
import { PhoneStatusEnum } from "src/app/models/phoneStatus";
import { Session } from "src/app/models/WebServer/session";
import { TranslationInfo } from "src/app/models/WebServer/translationInfo";
import { PartecipantInfo } from "src/app/models/WebServer/partecipantInfo";

const appStateReducer = createReducer(
    initialAppState,
    on(appActions.configLoaded, (state: AppState, { config, partecipant }) => {

        let errorInfo: ErrorInfo | undefined;

        if (!partecipant.id) { // strange.., so.. ERROR
            errorInfo = new ErrorInfo();
            errorInfo.message = I18Service.get('POPUP.SESSIONNOTFOUNDBODY');
            errorInfo.showReloadButton = false;
        }
        const isCaller = partecipant.role == PartecipantRoles.SecondaryClient;
        const isReceiver = partecipant.role == PartecipantRoles.PrimaryClient;
        const isGuest = partecipant.role == PartecipantRoles.GuestClient;
        const appModes = (isGuest) ? [AppModesEnum.Chat] : []
        // console.log(`config loaded => partecipantId: ${partecipantId} - isCaller: ${isCaller} - isReceiver: ${isReceiver}`);
        return {
            ...state,
            ...{
                errorInfo,
                partecipantId: partecipant.id,
                isCaller,
                isReceiver,
                isGuest,
                role: partecipant.role,
                // commandsDisabled: isGuest, //non disabilitare i comandi al guest

                appModes,
                //config,
                // signalRHub: {
                //     automaticReconnect: true,
                //     url: config.signalRUlr,
                //     hubName: ''
                // }
            }
        }
    }),

    on(appActions.loadingMessage, (state: AppState, { message }) => {
        console.log(`***** appActions.loadingMessage => ${message}`);
        return {
            ...state,
            loadingMessage: message
        }
    }),
    on(appActions.signalrHubConnected, (state: AppState, { connectionId }) => {
        // console.log(`**** ppActions.signalrHubConnected ${connectionId} => appready: ${ !!state.sessionLoaded && !!state.wssSipRegistered && !!connectionId}
        // !!state.sessionLoaded:${!!state.sessionLoaded} - !!state.signalrConnectionId: ${!!connectionId} - !!state.wssSipRegistered:${!!state.wssSipRegistered}
        // `)
        return {
            ...state,
            ...{ signalrConnectionId: connectionId, appReady: !!state.sessionLoaded && !!state.wssSipRegistered && !!connectionId}
        }
    }),

    on(sessionActions.sessionLoaded, (state: AppState, _) => {
        // console.log(`**** ppActions.sessionLoaded ${true} => appready: ${ true && !!state.wssSipRegistered && !!state.signalrConnectionId}
        // !!state.sessionLoaded:${true} - !!state.signalrConnectionId: ${!!state.signalrConnectionId} - !!state.wssSipRegistered:${!!state.wssSipRegistered}
        // `)
        return {
            ...state,
            ...{ sessionLoaded: true, appReady: !!state.signalrConnectionId && !!state.wssSipRegistered }
        }
    }),
    on(appActions.wssSipRegistered, (state: AppState, { is }) => {
        // console.log(`**** ppActions.wssSipRegistered ${is} => appready: ${ !!state.sessionLoaded && !!state.signalrConnectionId && (is || state.isReceiver)}
        // !!state.sessionLoaded:${!!state.sessionLoaded} - !!state.signalrConnectionId: ${!!state.signalrConnectionId} - (is || state.isReceiver):${(is || state.isReceiver)}
        // `)
        const newPhoneStatus = state.phoneStatus != PhoneStatusEnum.NONE ? state.phoneStatus : PhoneStatusEnum.IDLE;
        return {
            ...state,
            ...{ wssSipRegistered: is, appReady: !!state.sessionLoaded && !!state.signalrConnectionId && (is || state.isReceiver), phoneStatus: newPhoneStatus }
        }
    }),

    on(appActions.showErrorPage, (state: AppState, { message, imagePath, showReload }) => {
        var ei = state.errorInfo;
        if (!ei) {
            ei = new ErrorInfo();
            ei.message = message;
            ei.showReloadButton = (showReload !== undefined) ? showReload : ei.showReloadButton;
            if (imagePath !== undefined && AppHelper.fileExists(imagePath)) {
                ei.imagePath = imagePath;
            }
        }
        return {
            ...state,
            ...{ errorInfo: ei} //, sessionLoaded: true }
        }
    }),

    on(sessionActions.sessionOk, sessionActions.sessionReload, (state: AppState, { session }) => {
        console.log(`session ok`, session);
        const sessionConfig = { ...initialSessionConfig, ...session.config, agencySettings: { ...initialSessionConfig?.agencySettings, ...session.config?.agencySettings } }; //overwrite default sessionconfig
        //const sessionConfig = defaultsDeeps(initialSessionConfig, session.config);
        sessionConfig.agencySettings.agency = session.agency;
        const expired = new Date(session.expiration) < new Date()
        return {
            ...state,
            ...{ session: { ...session, ...{ config: sessionConfig } }, sessionExpired: expired, messages: setInterlocutor(state.messages, state, session.parameters.partecipantIds) }
        }
    }),

    on(sessionActions.partecipantOk, (state: AppState, { partecipant }) => {
        console.log(`partecipant ok => partecipantInfo: `, partecipant);
        return {
            ...state,
            ...{ partecipantInfo: partecipant }
        }
    }),

    on(appActions.pageStateChange, (state: AppState, { nextState }) => {
        return {
            ...state,
            ...{ pageStatus: nextState }
        }
    }),
    on(appActions.appModeChange, (state: AppState, { mode }) => {
        let appModes = [...state.appModes];
        // se c'è già.. rimuove ma non se è "solo" (toggle)
        if (appModes.includes(mode) && appModes.length > 1) {
            appModes = appModes.filter(x => x !== mode)
        // }
        // else if (state.isGuest) {
        //     appModes = [mode]
        } else { // aggiungiamo ma se Video togliamo foto, se Photo togliamo video

            switch (mode) {
                case AppModesEnum.Map: appModes = []; break;
                case AppModesEnum.Video: appModes = appModes.filter(x => x !== AppModesEnum.Photo); break
                case AppModesEnum.Photo: appModes = appModes.filter(x => x !== AppModesEnum.Video); break
            }

            if (mode != AppModesEnum.Map && !appModes.includes(mode)) {
                appModes.push(mode);
            }


        }
        const appInMultimedia = appModes.length > 0;
        var videoEnabled = state.videoEnabled;
        if (state.isCaller || state.isGuest) {
            videoEnabled = appModes.includes(AppModesEnum.Video) ? true : false;
        }
        // const cameraEnabledForSnapshot = appModes.includes(AppModesEnum.Photo);
        console.log(`appModeChange: ${mode} => ${appModes} zzzzzzzzz`);

        return {
            ...state,
            ...{ appModes, appInMultimedia, videoEnabled }//, cameraEnabledForSnapshot}
        }
    }),
    on(appActions.setAppModes, (state: AppState, { modes }) => {
        console.log(`setAppModes: ${modes} zzzzzzzzz`);
        const appInMultimedia = modes.length > 0;
        var videoEnabled = state.videoEnabled;
        if (state.isCaller || state.isGuest) {
            videoEnabled = modes.includes(AppModesEnum.Video) ? true : false;
        }
        return {
            ...state,
            ...{ appModes: modes, appInMultimedia, videoEnabled }
        }
    }),
    on(appActions.openVideoSection, (state: AppState) => {

        const appModes = [...state.appModes];
        const videoEnabled = state.videoEnabled;
        if (videoEnabled && !appModes.includes(AppModesEnum.Video)){
            appModes.push(AppModesEnum.Video);
        }
        console.log(`openVideoSection ${appModes} zzzzzzz`);
        return {
            ...state,
            ...{appModes}
        }
    }),
    on(appActions.retrieveDevices, (state, { availableDevices }) => {
        return { ...state, ...{ availableDevices} }
    }),
    on(sessionActions.chatHistoryLoaded, (state: AppState, { messages }) => {


        if (messages.find(z => !z.rtpCallId)) {
            console.log(`*********** HISTORY LOADED messages without rtpCallID`, messages.filter(z => !z.rtpCallId));
        }


        var chatMessages = setInterlocutor(messages.filter(z => !z.type || z.type == MediaTypesEnum.Document), state, state.session.parameters.partecipantIds)
        var mediaMessages = setInterlocutor(messages.filter(z => z.type == MediaTypesEnum.Image), state, state.session.parameters.partecipantIds)

        const currentChats = [...state.messages];
        chatMessages = mergeMessages(chatMessages, currentChats);//chatMessages.concat(currentChats);

        const currentMedia = [...state.mediaMessages];
        mediaMessages = mergeMessages(mediaMessages, currentMedia);//mediaMessages.concat(currentMedia);

        const unreadMessage = chatMessages.filter(z => !z.messageInfo?.readAt && !z.me).length;
        const unseenImages = mediaMessages.filter(z => !z.messageInfo?.readAt && !z.me).length;
        console.log(`loaded chat messages ${chatMessages.length} - current: ${currentChats.length}`);
        return {
            ...state,
            ...{ messages: chatMessages, mediaMessages: mediaMessages, messagesLoaded: true, unreadMessage, unseenImages }
        }
    }),
    on(appActions.sendTextMessage, (state: AppState, { body, trInfo }) => {
        const messages = [...state.messages];
        const myMsg: Message = createMessage(state.partecipantId, body, trInfo, state);
        messages.push(myMsg);

        //console.log(`====sendTextMessage chat messages - current: ${messages.length}`);
        return {
            ...state,
            ...{ messages }
        }
    }),
    on(appActions.recvTextMessage, (state: AppState, { body, from, trInfo, rtpCallId, contentLanguage }) => {
        const messages = [...state.messages];
        const msg: Message = createMessage(from, body, trInfo, state, rtpCallId);
        msg.contentLanguage = contentLanguage;
        messages.push(msg);        
        const unreadMessage = messages.filter(z => !z.messageInfo?.readAt && !z.me).length;
        //console.log(`====recvTextMessage chat messages - current: ${messages.length} - unread:${unreadMessage}`);
        return {
            ...state,
            ...{ messages, unreadMessage }
        }
    }),
    on(appActions.updateMessage, (state: AppState, {message}) => {
        const myId = state.partecipantId;
        const myMessage = message.from.toLowerCase() == myId.toLowerCase()
        const trMsg = {...message};
        setTranslatedContent(trMsg, state.isCaller, myMessage);
        return{
            ...state,
            messages: state.messages.map((z, i) => z.rtpCallId == message.rtpCallId ? trMsg : z)
        }
    }),
    on(appActions.recvMediaMessage, (state: AppState, { message }) => {

        const m = setInterlocutor([message], state, state.session.parameters.partecipantIds)[0];
        const messages = [...state.messages];
        const mediaMessages = [...state.mediaMessages];
        if (m.type == MediaTypesEnum.Document) {
            messages.push(m);
        }
        if (m.type == MediaTypesEnum.Image) {
            mediaMessages.push(m);
        }

        const unreadMessage = messages.filter(z => !z.messageInfo?.readAt && !z.me).length;
        const unseenImages = mediaMessages.filter(z => !z.messageInfo?.readAt && !z.me).length;

        return {
            ...state,
            ...{ messages, mediaMessages, unreadMessage, unseenImages }
        }
    }),

    on(appActions.toggleRemoteCommand, (state: AppState, { enabled }) => {
        var commandsDisabled: boolean;
        if (enabled === null)
            commandsDisabled = !state.commandsDisabled;
        else
            commandsDisabled = enabled;
        return {
            ...state,
            ...{ commandsDisabled }
        }
    }),
    on(appActions.mirrorUser, (state: AppState, { enabled }) => {
        return {
            ...state,
            ...{ mirrorUser: enabled }
        }
    }),
    on(appActions.toggleVideo, (state: AppState) => {
        const videoEnabled = !state.videoEnabled;
        console.log(`toggleVideo new=>${videoEnabled} zzzzzzz`);
        return {
            ...state,
            videoEnabled
        }
    }),
    on(appActions.streamVideoDraw, (state: AppState, {enabled}) => {
        return {
            ...state,
            videoEnabled: enabled
        }
    }),
    on(appActions.myAppStateChange, (state: AppState, {partecipantStatus}) =>{
        
        return {
            ...state,
            isRegistered: partecipantStatus.isRegistered || false,
            videoEnabled: partecipantStatus.videoEnabled || false,
            microphoneEnabled: partecipantStatus.microphoneEnabled || false,
            appModes: partecipantStatus.appModes || [],
            phoneStatus: partecipantStatus.phoneStatus || PhoneStatusEnum.NONE,
            appInMultimedia: partecipantStatus.appModes && partecipantStatus.appModes.length > 0 || false,
            commandsDisabled: partecipantStatus.commandsDisabled || false,
        }
    }),
    on(appActions.toggleMicrophone, (state: AppState) => {
        const microphoneEnabled = !state.microphoneEnabled;
        return {
            ...state,
            ...{ microphoneEnabled }
        }
    }),
    on(sessionActions.terminateSession, (state: AppState) => { //inviato dal primary
        return {
            ...state,
            ...{ sessionExpired: true, sessionExtending: false }
        }
    }),
    on(sessionActions.extendSession, (state: AppState) => { //inviato dal primary
        return {
            ...state,
            ...{ sessionExpired: false, sessionExtending: true }
        }
    }),
    on(appActions.sessionExpired, (state: AppState) => { //ricevuto dal primary
        return {
            ...state,
            ...{ sessionExpired: true, sessionExtending: false }
        }
    }),
    // on(sessionActions.terminateSession, (state: AppState) => { //TODO: stabilire cosa fare su receiver quando si termina sessione
    //     return {
    //         ...state,
    //         ...{commandsDisabled: true}
    //     }
    // }),
    on(phoneActions.phoneChangeStatus, (state: AppState, { status }) => {
        return {
            ...state,
            phoneStatus: status
        }
    }),
    on(appActions.batteryLevel, (state: AppState, { level }) => {
        return {
            ...state,
            ...{ batteryLevel: level }
        }
    }),
    on(appActions.connectionSpeed, (state: AppState, { speed }) => {
        return {
            ...state,
            ...{ connectionSpeed: speed }
        }
    }),
    on(sessionActions.lastServerPosition, (state: AppState, { evt }) => {
        const coords = evt.data?.geoLocInfo
        return {
            ...state,
            ...{ lastPositionData: { time: new Date(evt.timestamp || 0), coords: coords }, realTimePosition: false }
        }
    }),

    on(appActions.markMediaAsSeen, (state: AppState, { mediaIdx }) => {
        const mediaMessages = [...state.mediaMessages];
        const m = { ...mediaMessages[mediaIdx] };
        var unseenImages = state.unseenImages
        if (m) {

            unseenImages--;
            if (unseenImages < 0) unseenImages = 0;
            mediaMessages[mediaIdx] = setReadAt(m);
        }
        return {
            ...state,
            ...{ mediaMessages, unseenImages }
        }
    }),
    on(appActions.markMessageDelivered, (state: AppState, { message }) => {
        const messages = [...state.messages];

        function setDeliveredAt(m: Message): Message {
            if (m.messageInfo && m.messageInfo.deliveredAt) {
                return m
            }

            const newMessage = { ...m };
            newMessage.messageInfo = { deliveredAt: new Date() };
            return newMessage;
        }
        messages.forEach((m, idx) => {
            const deliveredMessage = message as Message;
            if (message == 'all' || (m.content == deliveredMessage.content && m.from == deliveredMessage.from)) {
                const mm = {...m}
                messages[idx] = setDeliveredAt(mm);
            }
        });
        return {
            ...state,
            ...{ messages }
        }
    }),
    on(appActions.markMessageAsRead, (state: AppState, { message }) => {
        const messages = [...state.messages];


        if (message == 'all') {

            messages.forEach((m, idx) => {
                m
                if (!m.messageInfo?.readAt && !m.me)
                    messages[idx] = setReadAt(m);
            });
        }
        else {
            const deliveredMessage = message as Message;
            const m = messages.find(z => z.rtpCallId == deliveredMessage.rtpCallId)
            if (m) {
                setReadAt(m);
            }
        }
        const unreadMessage = messages.filter(z => !z.messageInfo?.readAt && !z.me).length;
        return {
            ...state,
            ...{ messages, unreadMessage }
        }
    }),
    on(appActions.markMyMessageAsRead, (state: AppState, { message }) => {
        const messages = [...state.messages];
        if (message == 'all') {
            messages.forEach((m, idx) => {
                if (!m.messageInfo?.readAt && m.me)
                    messages[idx] = setReadAt(m);
            });
        }
        else {
            const deliveredMessage = message as Message;
            const m = messages.find(z => z.content = deliveredMessage.content)
            if (m) {
                setReadAt(m);
            }
        }
        return {
            ...state,
            ...{ messages }
        }
    }),
    on(sessionActions.expirationUpdated, (state: AppState, { newExpiration }) => {
        console.log(`expiration updated => partecipant: `, newExpiration);

        const newSession: Session = {
            ...state.session, ...{
                expiration: newExpiration
            }
        };
        return {
            ...state,
            ...{ session: newSession, sessionExtending: false }
        }
    }),

    on(appActions.translationAuto, (state: AppState, {auto}) =>{
        return {
            ...state,
            ...{translationAuto: auto}
        }
    }),

    on(appActions.translationSelectedLanguage, (state: AppState, {lang}) =>{
        return {
            ...state,
            ...{translationSelectedLanguage: lang}
        }
    }),

    on(appActions.changeLanguage, (state: AppState, {lang})=>{

        return {
            ...state,
            ...{guiLanguage: lang, messages: setInterlocutor(state.messages, state, state.session.parameters.partecipantIds)}
        }
    }),
    on(appActions.operatorZoomId, (state: AppState, {id})=>{
        console.log(`app.reducer operatorZoomId => ${id}`)
        return {
            ...state,
            ...{operatorZoomId: id}
        }
    })


);

export function appReducer(state: AppState | undefined, action: Action) {
    return appStateReducer(state, action);
}

function createMessage(from: string, body: string, trInfo: TranslationInfo[] | undefined, state: AppState, rtpCallId?: string): Message {

    const msg: Message = setInterlocutor(
        [{            
            idx: 0,
            from,
            content: body,
            translationInfo: trInfo,
            timestamp: new Date(),
            rtpCallId: rtpCallId,
        }], state, state.session.parameters.partecipantIds)[0];

    return msg;
}

function setInterlocutor(msgs: Message[], state: AppState, partecipantIds: PartecipantInfo[]): Message[] {
    const myId = state.partecipantId;
     
    // state.sess
    return msgs.map(x => {
        const msg: Message = { ...x };
        const myMessage = x.from.toLowerCase() == myId.toLowerCase()
        if (myMessage) {
            msg.me = true;
            // msg.from = I18Service.get('Chat.Me');
            msg.alias = I18Service.get('Chat.Me');

        } else {
            msg.me = false;
            const partecipantInfo = partecipantIds.find(z => z.partecipantId == x.from);
            msg.idx = partecipantIds.findIndex(z => z.partecipantId == x.from);
            var alias = I18Service.roleAndAlias(partecipantInfo);
            
            msg.alias = alias ?? msg.from;
            msg.role = partecipantInfo?.partecipantRole;
        }
        setTranslatedContent(msg, state.isCaller, myMessage);
        return msg
    });
}

function setTranslatedContent(msg: Message, isCaller: boolean, isMyMessage: boolean) {
    const trInfo = msg.translationInfo;
        
    const lang = I18Service.getNavigatorLanguage();
    // console.log(`*** translation myLang ${lang} - iamCaller: ${isCaller} - isMymessage: ${isMyMessage} => ${msg.content}`)
    var trInfoForMe = trInfo ? trInfo.find( z => z.targetLanguage == lang) : undefined; //guest

    if(isCaller || isMyMessage) trInfoForMe = trInfo ? trInfo.find( z => msg.from == z.owner) : undefined; // fix per caller #64973
    
    if( trInfoForMe && trInfoForMe.targetLanguage == trInfoForMe.startingLanguage) trInfoForMe = undefined; // viene nascosta la traduzione

    if (trInfoForMe){
        msg.translatedInfo = trInfoForMe;
    }
}

function setReadAt(m: Message): Message {
    if (m.messageInfo?.readAt) {
        return m
    }
    const newMessage = { ...m };
    newMessage.messageInfo = { ...m.messageInfo, ...{ readAt: new Date() } }

    return newMessage;
}

function mergeMessages(m1: Message[], m2: Message[]): Message[] {
    const merged: Message[] = [...m1];

    const missing = m2.filter( e => !m1.some( z => z.rtpCallId === e.rtpCallId));
    if (missing.length) console.log(`********************** missing ${missing.length}`, missing, m1, m2);
    missing.forEach( m => {
        const index = m1.findIndex(event => new Date(event.timestamp) > new Date(m.timestamp));
    
        if (index === -1) {
            // If no such index is found, the new event has the latest timestamp and should be added to the end
            merged.push(m);
        } else {
            // Otherwise, insert the new event at the found index
            merged.splice(index, 0, m);
        }
    });
    
    return merged;
}


// function decodeCompactGuid(compactGuid: string): string {

//     function replaceAll(original: string, find: string, replacement: string): string {
//         const pieces = original.split(find);
//         return pieces.join(replacement);
//       }

//     let str = replaceAll(compactGuid, "_", "/");
//     str = replaceAll(str, "-", "+");
//     str = `${str}==`;
//     // `${compactGuid.replace("_", "/").replace("-", "+")}==`;
//     let data = '';
//     try { data = window.atob(str); } catch (e) { 
//         console.error(e);
//         return '';
//     }
//     const array = Uint8Array.from(data, b => b.charCodeAt(0));
//     let orderArray: number[] = [];
//     if (array.length == 16) {
//       orderArray = [
//         array[3], array[2], array[1], array[0],
//         array[5], array[4],
//         array[7], array[6],
//         array[8], array[9],
//         array[10], array[11], array[12], array[13], array[14], array[15]
//       ]
//     }
//     var decodedGuid = new Uuid(orderArray);
//     let guid = decodedGuid.toString();
//     return guid;
//   }
