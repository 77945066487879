import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppHelper } from 'src/app/helpers/app.helper';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';
import { MediaTypesEnum } from 'src/app/models/WebServer/mediaTypesEnum';
import { PartecipantInfo } from 'src/app/models/WebServer/partecipantInfo';
import { AppState, initialAppState } from 'src/app/store/states/app.state';
import { SvgConstants } from 'src/app/svgConstants';
import { AppConfigService } from 'src/app/services/app.config.service';
import { Message } from 'src/app/models/WebServer/message';
import { RootState } from 'src/app/store/root.state';
import { Store } from '@ngrx/store';

import * as appActions from "src/app/store/actions/app.actions";
import { PartecipantRoles } from 'src/app/models/WebServer/partecipantRoles';
import { TextTranslationService } from 'src/app/services/textTranslation.service';
import { toTranslationInfo } from 'src/app/models/WebServer/translationResponse';
import { LanguageCode } from 'src/app/models/languageCode';
import { TranslationInfo } from 'src/app/models/WebServer/translationInfo';
import { I18Service } from 'src/app/services/i18.service';
import * as selectorState from "src/app/store/selectors/app.selectors";
import { Constants } from 'src/app/constants';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() disabled!: boolean;
  @Input('shown') set shown(b: boolean) {
    this._shown = b;
    if (b && !this.scrollBottomVisible) {
      this.scrollBottom();
    }
  }
  @Input('userLanguage') set userLanguage(l: string) {
    if (l) {
      l = l.split('-')[0];
      this.changeSelectedLanguageCode(l);

    }
  }

  _shown = false;

  @Input() AS!: AppState;
  @ViewChild('messagelist', { read: ElementRef }) messagelist!: ElementRef;
  @ViewChild('chatText', { read: ElementRef }) chatText!: ElementRef;
  @ViewChild('translateText', { read: ElementRef }) translateText!: ElementRef;

  MediaTypesEnum = MediaTypesEnum
  appModes = AppModesEnum;
  public chatIncomingSound: any;
  appHelper = AppHelper;
  PartecipantRoles = PartecipantRoles;

  remoteContent = '';
  chatBuffer = '';
  public inputMessage: string = '';
  messageTimer: any = null;
  container?: HTMLElement;
  files?: FileList;
  fileName?: string;
  resizedSize?: number;
  actualFileSize?: number;
  interlocutor?: PartecipantInfo;
  phoneModel?: string;
  mediaLink?: string;
  audioEnabled = false;
  today: Date;
  svgConstants = SvgConstants;
  translationInfo?: TranslationInfo;
  selectedLanguageCode = initialAppState.translationSelectedLanguage;// Constants.DEFAULT_LANGUAGE;
  showTranslationBox = false;
  scrollBottomVisible = false;
  autoTranslateChecked = false;
  languageList: LanguageCode[] = []
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private store: Store<RootState>,
    public config: AppConfigService,
    public textTranslationService: TextTranslationService
  ) {
    this.chatIncomingSound = new Audio('/assets/audio/chat_incoming.mp3');
    this.chatIncomingSound.loop = false;
    this.chatIncomingSound.muted = false;
    this.today = new Date();
    
    
    this.store.select(selectorState.guiLanguage).pipe(takeUntil(this.destroy$)).subscribe(l => {
      // this.selectedLanguageCode = config.language;
      this.languageList = I18Service.availableTranslations();
      console.log(`I18Service.LANGUAGE_CHANGE => ${l}`, this.languageList)
    });

    this.store.select(selectorState.translationSelectedLanguage).pipe(takeUntil(this.destroy$)).subscribe(l => {
      if(l) this.selectedLanguageCode = l;
    })
    
    
  }
  ngAfterViewInit(): void {
    const ne = this.messagelist?.nativeElement;
    if (ne) {
      // detect dello scroll per attivare il pulsante scroll bottom 
      ne.addEventListener('scroll', (event: any) => this.onScrollChat());

      // nel caso non sia attivo pulsante (lista scrollata in basso), in nuovi messaggi autoscroll
      const observer = new MutationObserver((mutations, observer) => {
        if (!this.scrollBottomVisible && this._shown) {
          this.scrollBottom();
        }
      });
      observer.observe(ne, { subtree: true, attributes: true });
      // this.scrollBottom(); // porta all'ultimo messaggio all'avvio 
    }
  }

  ngOnDestroy(): void {
    console.log(`ngOndestroy chat.component`)
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
  }

  private comunicateAllRead() {
    if (!this._shown || !this.AS.unreadMessage || this.scrollBottomVisible) return;
    this.store.dispatch(appActions.markMessageAsRead({ message: 'all' }))
  }

  private onScrollChat() {
    this.scrollBottomVisible = this.checkScrollCondition();
    this.comunicateAllRead();
  }

  private checkScrollCondition(): boolean {
    const ne = this.messagelist?.nativeElement;
    if (ne) {
      let scrollHeight: number = ne.scrollHeight;
      let scrollTop: number = ne.scrollTop;
      let clientHeight: number = ne.clientHeight;
      let remainingHeigth: number = (scrollHeight - scrollTop) - clientHeight;
      return remainingHeigth > 20; // && remainingHeigth > 0;
    }
    return true;
  }

  scrollBottom() {
    window.setTimeout(() => {
      const ne = this.messagelist?.nativeElement;
      if (ne) {
        ne.scrollTop = ne.scrollHeight;
      }
    }, 100)

    if (this._shown && this.AS.unreadMessage) {
      this.comunicateAllRead();
    }
  }

  // private hasKnownExtension(message: Message): boolean {
  //   if (!message.fileName || !message.type) return false;
  //   var fileParts: string[] = message.fileName.split('.');
  //   if (fileParts.length <= 1) return false;
  //   message.extension = fileParts[fileParts.length - 1];
  //   return Constants.KNOWN_ATTACHMENT_EXTENSIONS.some(e => e == message.extension);
  // }

  // private isMediaMessage(message: Message) {
  //   return message?.type == MediaTypesEnum.Image;
  // }

  // private isDocumentMessage(message: Message) {
  //   return message?.type == MediaTypesEnum.Document;
  // }

  // private isTextMessage(message: Message) {
  //   return !message.type;
  // }
  // private getTextMessageIcon(message: Message): string {

  //   if (!message.fileName || !message.type) return '/assets/images/document.png';
  //   var fileParts: string[] = message.fileName.split('.');
  //   if (fileParts.length <= 1) return '/assets/images/document.png';
  //   const extension = fileParts[fileParts.length - 1];

  //   if (['docx', 'doc'].includes(extension)) return '/assets/images/docx.png';
  //   if (['pdf'].includes(extension)) return '/assets/images/pdf.png';
  //   return '/assets/images/document.png';
  // }

  // private isMediaString(message: string) {
  //   return (message.trim().indexOf('http://') > -1 || message.trim().indexOf('https://') > -1) && message.indexOf('api/event/media') > -1;
  // }

  public sendSpanMessageWithButton() { //TODO -done
    if (this.inputMessage) {
      const msg = this.chatText.nativeElement.textContent.trim();
      if(!this.config.isCaller){
        this.SendTranslateMessage(msg);
      }
      else{
        this.finishSendMessage(msg);
      }
    }
  }

  private SendTranslateMessage(msg: string) {
    if(this.autoTranslateChecked){
      // let input : TranslationInput = {
      //   inputText: this.inputMessage,
      //   targetLanguage: this.selectedLanguageCode,
      //   startingLanguage: this.config.language
      // }
      // this.translate(input).then((tr: TranslationResponse) => {
      //   this.translationInfo = toTranslationInfo(tr);
      //   this.finishSendMessage(msg);
      // });

      this.textTranslationService.translateText(msg, this.config.language, this.selectedLanguageCode.code!)
        .then( tr => {
          this.translationInfo = toTranslationInfo(tr, this.config.partecipantId);
          this.finishSendMessage(msg);
        })


    }
    else if(this.translationInfo){
      this.translationInfo.translatedText = this.translateText.nativeElement.textContent.trim();
      this.finishSendMessage(msg);
    }
    else{
      this.finishSendMessage(msg);
    }
  }

  private finishSendMessage(msg: string){
    this.store.dispatch(appActions.sendTextMessage({ body: msg, trInfo: this.translationInfo ? [this.translationInfo] : undefined, toIds: appActions.SEND_TO_ALL }));
    this.clearMessage();
    if(!this.config.isCaller){
      this.clearTranslateMessage();
    }
  }

  public translateChatMessage(message: Message){
    this.textTranslationService.translateChatMessage(message, this.selectedLanguageCode.code, this.autoTranslateChecked, true)
    .then( (resp) => {});

  }
  public detectAndTranslateChatMessage(message: Message){

    this.textTranslationService.translateChatMessage(message, this.selectedLanguageCode.code, this.autoTranslateChecked)
    .then( (resp) => {
      // if(resp.isCaller && resp.tr.confidence >= this.config.agencySettings.translationMaxConfidence) {
      //   this.changeSelectedLanguageCode(resp.tr.startingLanguage);
      // }
    });


    // const fromPartecipant = this.config.partecipantIds.find( z => z.partecipantId == message.from);
    // const fromIsCaller = fromPartecipant && fromPartecipant.partecipantRole == PartecipantRoles.SecondaryClient;


    // let input : TranslationInput = {
    //   inputText: message.content,
    //   targetLanguage: this.config.language
    // }

    // if(this.autoTranslateChecked){
    //   input.startingLanguage = fromIsCaller ? this.selectedLanguageCode : (message.contentLanguage || this.selectedLanguageCode)
    // }

    // this.translate(input).then((tr : TranslationResponse) => {
    //   console.log(`checkbox status: ${this.autoTranslateChecked}`)
    //   var minConfidence = this.config.agencySettings?.translationMinimumConfidence;
    //   // if(minConfidence && tr.confidence < minConfidence ){
    //   //   NotificationService.showError(`La traduzione non è stata possibile`);          
    //   // }
    //   // else
    //   {

        
    //     if (fromIsCaller) { // solo se cittadino, cambio lingua
    //       this.changeSelectedLanguageCode(tr.startingLanguage);
    //     }
    //     const translatedMessage : Message = this.appHelper.deepCopy(message);
    //     const translationInfo = toTranslationInfo(tr);
    //     translatedMessage.translationInfo = [translationInfo]
    //     translatedMessage.contentLanguage = tr.startingLanguage;
    //     this.store.dispatch(appActions.updateMessage({message : translatedMessage})) 
    //     //TODO verificare se va bene che passi tramite action o se sia meglio passi per redux nei casi in cui arrivi una lingua non supportata
    //   }
    // });
  }

  public translateSendMessageClick(){
    if(this.inputMessage && !this.autoTranslateChecked){
      // let input : TranslationInput = {
      //   inputText: this.inputMessage,
      //   targetLanguage: this.selectedLanguageCode,
      //   startingLanguage: this.config.language
      // }

      // this.translate(input).then((tr: TranslationResponse) => {
      //   // console.log(`checkbox status: ${this.autoTranslateChecked}`)
      //   var minConfidence = this.config.agencySettings?.translationMinimumConfidence;
      //   // if(minConfidence && tr.confidence < minConfidence ){
      //   //   NotificationService.showError(`La traduzione non è stata possibile`);          
      //   // }
      //   // else
      //   {
      //     this.showTranslationBox = true;
      //     this.translationInfo = toTranslationInfo(tr);
          
      //     this.translateText.nativeElement.innerHTML = tr.translatedText; 
      //   }
      // });

      this.textTranslationService.translateText(this.inputMessage, this.config.language, this.selectedLanguageCode.code)
        .then( tr =>{
          this.showTranslationBox = true;
          this.translationInfo = toTranslationInfo(tr, this.config.partecipantId);
          
          this.translateText.nativeElement.innerHTML = tr.translatedText; 
        })



    }
  }

  // private translate(input: TranslationInput) : Promise<TranslationResponse> {
  //   return new Promise((resolve, reject) => {    
  //     this.textTranslationService.translate(input).subscribe({
  //       next: (translationResponse : TranslationResponse) => {
  //         // console.log(`confidence: ${translationResponse.confidence}`)
  //         resolve(translationResponse);
  //       },
  //       error: (error: any) => {
  //         FELoggerService.error(`translateMessage error`, LogSource.Default, error)
  //         NotificationService.showError(error + '');
  //         reject();
  //       }
  //     })
  //   })
  // }

  public clearMessage() {
    this.chatText.nativeElement.textContent = '';
    this.chatText.nativeElement.innerHTML = ''
    this.inputMessage = '';

    if(!this.config.isCaller){
      this.clearTranslateMessage();
    }
    this.placeCaretAtEnd();
  }

  public clearTranslateMessage(){       
    this.translateText.nativeElement.textContent = ''
    this.translateText.nativeElement.innerHTML = ''
    this.translationInfo = undefined;
    // this.showTranslationBox = false;
  }

  public spanKeyPress(key: KeyboardEvent): void {
    if (!key) {
      return;
    }
    this.inputMessage = this.chatText.nativeElement.textContent.trim();
    if (!this.inputMessage){
      this.clearTranslateMessage();
    }

    const pressedKey = key.code || key.key;
    if ((pressedKey == 'Enter' || pressedKey == 'NumpadEnter') && !key.shiftKey) {
      this.sendSpanMessageWithButton();
    }
  }

  public managePaste() {

    setTimeout(() => { // rimuove formattazioni strane
      const pasted = this.chatText.nativeElement.textContent.trim();
      this.chatText.nativeElement.innerHTML = pasted;
      this.inputMessage = pasted;
      this.placeCaretAtEnd();
    }, 0);
  }

  private placeCaretAtEnd() {
    const el = this.chatText.nativeElement;
    el.focus();
    if (typeof window.getSelection != "undefined"
      && typeof document.createRange != "undefined") {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      if (sel) {
        sel?.removeAllRanges();
        sel?.addRange(range);
      }
    } else if (typeof (<any>document.body).createTextRange != "undefined") {
      var textRange = (<any>document.body).createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  changeSelectedLanguageCode(value: string): void {

    this.store.dispatch(appActions.translationSelectedLanguage({lang: I18Service.getLanguageCode(value)}));
  }
  changeAutoTranslate(checked: boolean) {
    this.store.dispatch(appActions.translationAuto({auto: checked}));
  }
}
